import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MercadoPagoService {

  constructor(private http: HttpClient) {
  }

  mercadoPago(item): Observable<any> {
    return this.http.post<any>(environment.paymentMP, item);
  }
}
